import React from "react";
import {Typography} from '@builder/component-library';
// Customizable Area Start
import {StyleSheet } from "react-native";
// Customizable Area End
import FingerprintPaymentAuthentication2Controller, {
  Props,
  configJSON,
} from "./FingerprintPaymentAuthentication2Controller";
class FingerprintPaymentAuthentication2 extends FingerprintPaymentAuthentication2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start   

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {   
    return (      
      <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
          <div style={styles.mainWrapper}>
            <Typography style={styles.titleText}>{configJSON.labelTitleText}</Typography>
            <Typography style={styles.bodyText}>{configJSON.labelBodyText}</Typography>
            <div>
              <h1>Register Your Biometric Authentication</h1>
              <button data-testid="registerFaceBtn" onClick={()=>this.handleBeginRegistration('face')}>Register Face</button>
              <button data-testid="registerFingerprintBtn" onClick={()=>this.handleBeginRegistration('fingerprint')}>Register Fingerprint</button>
              <button data-testid="registerTouchBtn"onClick={()=>this.handleBeginRegistration('touch')}>Register Touch ID</button>
              <div style={{ color: 'green' }}>{this.state.successMessage}</div>
              <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
            </div>

          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif",
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",
  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});
export default FingerprintPaymentAuthentication2;
// Customizable Area End