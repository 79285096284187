// Customizable Area Start
import React from "react";
import { Typography } from '@builder/component-library';
import {styles} from "./FingerprintPaymentAuthentication2.web";
import FingerprintPaymentAuthentication2Controller, { Props, configJSON } from "./FingerprintPaymentAuthentication2Controller";
export default class SignUpPage extends FingerprintPaymentAuthentication2Controller {
  constructor(props: Props) {
    super(props);   
  }  
  render() {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={styles.mainWrapper}>
            <Typography style={styles.titleText}>{configJSON.labelSignupTitle}</Typography>
            <Typography style={styles.bodyText}>{configJSON.labelSignupBody}</Typography>
            <div>
              <h1>{this.state.step === "mobile" ? "Enter Your Mobile Number" : "Enter OTP"}</h1>

              {this.state.step === "mobile" ? (
                <form onSubmit={this.handleSendOTP}>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    data-testid="mobileNumberInput"
                    value={this.state.mobileNumber}
                    onChange={(e) => this.handleChange('mobileNumber', e.target.value)}
                    style={styles.inputStyle}
                  />
                  <button type="submit" style={styles.buttonStyle}>Send OTP</button>
                </form>
              ) : (
                <form onSubmit={this.handleVerifyOTP}>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    data-testid="otpInput"
                    value={this.state.pin}
                    onChange={(e) => this.handleChange('pin', e.target.value)}
                    style={styles.inputStyle}
                  />
                  <button  type="submit" style={styles.buttonStyle}>Redirect to Easy Login</button>
                </form>
              )}
              <div style={{ color: 'green' }}>{this.state.successMessage}</div>
              <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <button onClick={this.alreadyEasyLogin} style={styles.buttonStyle}>
                You have already logged in via Easy Login
              </button>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
// Customizable Area End