// Customizable Area Start
import React from "react";
import { Typography } from '@builder/component-library';
import FingerprintPaymentAuthentication2Controller, { Props, configJSON } from "./FingerprintPaymentAuthentication2Controller";
import {styles} from "./FingerprintPaymentAuthentication2.web";
export default class LogInPage extends FingerprintPaymentAuthentication2Controller {
    constructor(props: Props) {
        super(props);       
    }    
    render() {
        return (
            <div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={styles.mainWrapper}>
                        <Typography style={styles.titleText}>{configJSON.labelSignupTitle}</Typography>
                        <Typography style={styles.bodyText}>{configJSON.labelSignupBody}</Typography>
                        <div>
                            <h2>Login</h2>
                            <button onClick={this.handleBeginLogin} type="submit" style={styles.buttonStyle}>Log In </button>
                            <div style={{ color: 'green' }}>{this.state.successMessage}</div>
                            <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
// Customizable Area End